<template>
  <a-config-provider :getPopupContainer="getPopupContainer" :locale="locale">
    <ThemeProvider is-root v-bind="themeConfig" :apply-style="false">
      <stepin-view
        system-name="猫狗兄弟管理系统"
        logo-src="@/assets/loadinglogo.png"
        :class="`${contentClass}`"
        :user="user"
        :navMode="navigation"
        :useTabs="useTabs"
        :themeList="themeList"
        v-model:show-setting="showSetting"
        v-model:theme="theme"
        @themeSelect="configTheme"
      >
        <template #title> header </template>
        <template #headerActions>
          <HeaderActions @showSetting="showSetting = true" />
        </template>
        <template #pageFooter>
          <PageFooter />
        </template>
        <template #themeEditorTab>
          <a-tab-pane tab="其它" key="other">
            <Setting />
          </a-tab-pane>
        </template>
      </stepin-view>
    </ThemeProvider>
  </a-config-provider>
  <login-modal :unless="['/login']" />
  <div class="changePasswordBackground" v-if="showChangePassword">
    <div class="changePasswordContainer">
      <a-button
        class="flex"
        type="primary"
        size="small"
        @click="closeChangePassword"
        >关闭</a-button
      >
      <div class="box">
        <a-form-item required label="旧密码">
          <a-input-password v-model:value="oldPassword" />
        </a-form-item>
        <a-form-item required label="新密码">
          <a-input-password v-model:value="newPassword" />
        </a-form-item>
        <a-form-item required label="确认新密码">
          <a-input-password v-model:value="confirmPassword" />
        </a-form-item>
        <div v-if="newPassword != null && confirmPassword != null">
          <div v-if="newPassword !== confirmPassword">新密码不一致</div>
        </div>
        <a-form-item>
          <a-button type="primary" size="large" @click="onSubmit()"
            >修改密码</a-button
          >
        </a-form-item>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";
import {
  useAccountStore,
  useMenuStore,
  useSettingStore,
  storeToRefs,
} from "@/store";
import avatar from "@/assets/avatar.png";
import { PageFooter, HeaderActions } from "@/components/layout";
import Setting from "./components/setting";
import { LoginModal } from "@/pages/login";
import { configTheme, themeList } from "@/theme";
import { ThemeProvider } from "stepin";
import { computed } from "vue";
import http from "@/store/http";

const { logout, profile } = useAccountStore();

const oldPassword = ref<string>();
const newPassword = ref<string>();
const confirmPassword = ref<string>();

useMenuStore()
  .getMenuList()
  .then((response) => {
    user.name = response.data.user.username;
    // user.avatar = account.avatar;
  });

const showSetting = ref(false);
const showChangePassword = ref(false);
const router = useRouter();

const closeChangePassword = () => {
  showChangePassword.value = false;
};

const locale = ref(zhCN);

const { navigation, useTabs, theme, contentClass } = storeToRefs(
  useSettingStore()
);
const themeConfig = computed(
  () => themeList.find((item) => item.key === theme.value)?.config ?? {}
);

const user = reactive({
  name: "admin",
  avatar: avatar,
  menuList: [
    // {
    //   title: "个人中心",
    //   key: "personal",
    //   icon: "UserOutlined",
    //   onClick: () => router.push("/profile"),
    // },
    {
      title: "设置",
      key: "setting",
      icon: "SettingOutlined",
      onClick: () => (showSetting.value = true),
    },
    {
      title: "修改密码",
      key: "changePassword",
      icon: "UserOutlined",
      onClick: () => (showChangePassword.value = true),
    },
    { type: "divider" },
    {
      title: "退出登录",
      key: "logout",
      icon: "LogoutOutlined",
      onClick: () => logout().then(() => router.push("/login")),
    },
  ],
});

function getPopupContainer() {
  return document.querySelector(".stepin-layout");
}

const onSubmit = async () => {
  try {
    // 发送修改密码请求到后端
    const response = await http.post("/admin/user/changePassword", {
      oldPassword: oldPassword.value,
      newPassword: newPassword.value,
    });
  } catch (error) {
    // 处理请求失败的情况，如提示用户网络错误等
    console.error("修改密码失败:", error);
  }
};
</script>

<style lang="less">
.stepin-view {
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    border-radius: 4px;
    background-color: theme("colors.primary.500");
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: theme("colors.primary.400");

    &:hover {
      background-color: theme("colors.primary.500");
    }
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0);
    border-radius: 4px;
    background: theme("backgroundColor.layout");
  }
}

html {
  height: 100vh;
  overflow-y: hidden;
}

body {
  margin: 0;
  height: 100vh;
  overflow-y: hidden;
}

.stepin-img-checkbox {
  @apply transition-transform;

  &:hover {
    @apply scale-105 ~ "-translate-y-[2px]";
  }

  img {
    @apply shadow-low rounded-md transition-transform;
  }
}
.changePasswordBackground {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0%;
  left: 0%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
.changePasswordContainer {
  width: 20vw;
  height: 100vh;
  position: absolute;
  left: 80%;
  background-color: white;
}
.box {
  width: 20vw;
  height: 50vh;
  position: absolute;
  top: 20%;
  text-align: center;
  background-color: white;
}
</style>
