<template>
  <ThemeProvider
    :color="{ middle: { 'bg-base': '#fff' }, primary: { DEFAULT: '#1896ff' } }"
  >
    <div class="login-box rounded-sm">
      <a-form
        :model="form"
        :wrapperCol="{ span: 24 }"
        @finish="login"
        class="login-form w-[400px] p-lg xl:w-[440px] xl:p-xl h-fit text-text"
      >
        <a-divider>欢迎使用智能安全屋后台管理系统</a-divider>
        <a-form-item :required="true" name="username">
          <a-input
            v-model:value="form.username"
            autocomplete="new-username"
            placeholder="请输入用户名或邮箱"
            class="login-input h-[40px]"
          />
        </a-form-item>
        <a-form-item :required="true" name="password">
          <a-input
            v-model:value="form.password"
            autocomplete="new-password"
            placeholder="请输入登录密码"
            class="login-input h-[40px]"
            type="password"
          />
        </a-form-item>
        <a-button
          htmlType="submit"
          class="h-[40px] w-full"
          type="primary"
          :loading="loading"
        >
          登录</a-button
        >

        <div
          class="terms"
          style="margin-top: 10px; display: flex; align-items: end"
        ></div>
      </a-form>
    </div>
  </ThemeProvider>
</template>
<script lang="ts" setup>
import { reactive, ref } from "vue";
import { useAccountStore } from "@/store";
import { ThemeProvider } from "stepin";
import {message} from "ant-design-vue";

export interface LoginFormProps {
  username: string;
  password: string;
}

const loading = ref(false);

const form = reactive({
  username: "",
  password: "",
});

const emit = defineEmits<{
  (e: "success", fields: LoginFormProps): void;
  (e: "failure", reason: string, fields: LoginFormProps): void;
}>();

const accountStore = useAccountStore();

function login(params: LoginFormProps) {
  loading.value = true;
  accountStore
    .login(params.username, params.password)
    .then((res:any) => {
      // if(res.code==200){
      //   emit("success", params)
      // }else{
      //
      // }

      emit("success", params);
    })
    .catch((e) => {
      emit("failure", e.message, params);
    })
    .finally(() => (loading.value = false));
}
</script>
