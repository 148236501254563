import { defineStore } from "pinia";
import http from "./http";
import { Response } from "@/types";
import { ref, watch } from "vue";
import { useMenuStore } from "./menu";
import { useAuthStore } from "@/plugins";
import {message} from "ant-design-vue";

export interface Profile {
  user: Account;
  permissions: string[];
  menus: string[];
  roles: string[];
}

export interface Account {
  id: number;
  username: string;
  avatar: string;
  gender: number;
}

export type TokenResult = {
  token: string;
  expireAt: number;
};

export interface menu {
  id: number;
  name: string;
  icon: string;
  path: string;
  component: string;
  showMenu: boolean;
  parentId: number;
}

export interface LoginResult {
  user: Account;
  menus: menu[];
  roles: string[];
  token: TokenResult;
}

export const useAccountStore = defineStore("account", {
  state() {
    return {
      account: {} as Account,
      permissions: [] as string[],
      role: "",
      logged: true,
      loading: false,
    };
  },
  persist: {
    enabled: true,
    strategies: [
      {
        storage: localStorage,
      },
    ],
  },
  actions: {
    async login(username: string, password: string) {
      return http
        .request<TokenResult, Response<LoginResult>>(
          "/admin/user/login",
          "post_json",
          { username, password }
        )
        .then(async (response) => {
          console.log(response);

          if (response.code === 200) {
            message.success('登录成功！')
            const { setAuthorities } = useAuthStore();
            const { setMenuList } = useMenuStore();

            this.logged = true;
            http.setAuthorization(
              `Bearer ${response.data.token.token}`,
              new Date(response.data.token.expireAt)
            );

            this.account = response.data.user;
            this.roles = response.data.roles;
            this.permissions = ["edit", "delete", "add"]; //response.data.menus.map(f => f.path);

            setMenuList(response.data.menus);
            setAuthorities(this.permissions);

            return response.data;
          } else {
            message.error(response.message)
            return Promise.reject(response);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async logout() {
      return new Promise<boolean>((resolve) => {
        localStorage.removeItem("stepin-menu");
        http.removeAuthorization();
        this.logged = false;
        resolve(true);
      });
    },
    async profile() {
      this.loading = true;
      return http
        .request<Account, Response<LoginResult>>("/admin/user/profile", "get")
        .then((response) => {
          console.log(response);

          if (response.code === 200) {
            const { setAuthorities } = useAuthStore();
            const { setMenuList } = useMenuStore();
            const data = response.data;
            this.account = data.user;
            this.permissions = ["edit", "delete", "add"]; //data.menus.map(f => f.path);
            this.roles = data.roles;

            setMenuList(data.menus);
            setAuthorities(this.permissions);

            return data.user;
          } else {
            return Promise.reject(response);
          }
        })
        .finally(() => (this.loading = false));
    },
    setLogged(logged: boolean) {
      this.logged = logged;
    },
  },
});
