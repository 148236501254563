import { defineStore, storeToRefs } from "pinia";
import http from "./http";
import { ref, watch } from "vue";
import { Response } from "@/types";
import { RouteOption } from "@/router/interface";
import { addRoutes, removeRoute } from "@/router/dynamicRoutes";
import { useSettingStore } from "./setting";
import { RouteRecordRaw, RouteMeta } from "vue-router";
import { useAuthStore } from "@/plugins";
import router from "@/router";

export interface MenuProps {
  id?: number;
  name: string;
  path: string;
  title?: string;
  icon?: string;
  badge?: number | string;
  target?: "_self" | "_blank";
  link?: string;
  component: string;
  renderMenu?: boolean;
  permission?: string;
  parent?: string;
  children?: MenuProps[];
  cacheable?: boolean;
  view?: string;
}

/**
 * 过滤菜单
 * @param routes
 * @param parentPermission
 */
function doMenuFilter(
  routes: Readonly<RouteRecordRaw[]>,
  parentPermission?: string
) {
  const { hasAuthority } = useAuthStore();

  const setCache = (meta: RouteMeta) => {
    meta._cache = {
      renderMenu: meta.renderMenu,
    };
  };

  routes.forEach((route) => {
    const required = route.meta?.permission ?? parentPermission;
    // if (route.meta?.renderMenu === undefined && required) {
    if (required) {
      route.meta = route.meta ?? {};
      setCache(route.meta);
      route.meta.renderMenu = hasAuthority(route.meta.permission);
    }
    if (route.children) {
      doMenuFilter(route.children, required);
    }
  });
}

/**
 * 重置过滤
 * @param routes
 */
function resetMenuFilter(routes: Readonly<RouteRecordRaw[]>) {
  const resetCache = (meta: RouteMeta) => {
    if (meta._cache) {
      meta.renderMenu = meta._cache?.renderMenu;
    }
    delete meta._cache;
  };
  routes.forEach((route) => {
    if (route.meta) {
      resetCache(route.meta);
    }
    if (route.children) {
      resetMenuFilter(route.children);
    }
  });
}

// 菜单数据转为路由数据
const toRoutes = (list: MenuProps[]): RouteOption[] => {
  return list.map((item) => ({
    name: item.name,
    path: item.path,
    component: item.component,
    children: item.children && toRoutes(item.children),
    meta: {
      title: item.title,
      permission: item.permission,
      icon: item.icon,
      renderMenu: item.renderMenu,
      cacheable: item.cacheable,
      href: item.link,
      badge: /^(false|true)$/i.test(item.badge + "")
        ? JSON.parse(item.badge + "")
        : item.badge,
      target: item.target,
      view: item.view,
    },
  }));
};

export const useMenuStore = defineStore(
  "menu",
  () => {
    const menuList = ref<MenuProps[]>([]);

    const loading = ref(false);

    const { filterMenu } = storeToRefs(useSettingStore());

    const checkMenuPermission = () => {
      if (filterMenu.value) {
        doMenuFilter(router.options.routes);
        console.log(router.options.routes);
      } else {
        resetMenuFilter(router.options.routes);
      }
    };

    checkMenuPermission();

    watch(filterMenu, checkMenuPermission);

    function convert2Menus(data: any) {
      let menus = [];
      data.forEach((item) => {
        menus.push({
          id: item.id,
          name: "menu_" + item.id,
          title: item.name,
          badge: "",
          icon: item.icon,
          target: "_self",
          path: item.path,
          component: item.component,
          renderMenu: item.showMenu == "1" ? true : false,
          parent: item.parentId ? "menu_" + item.parentId : null,
          permission: null,
          cacheable: true,
        });
      });

      const menuMap = menus.reduce((p, c) => {
        p[c.name] = c;
        return p;
      }, {});

      menus.forEach((menu) => {
        menu.renderMenu = !!menu.renderMenu;
        if (menu.parent) {
          const parent = menuMap[menu.parent];
          parent.children = parent.children ?? [];
          parent.children.push(menu);
        }
      });

      menus = menus.filter((menu) => !menu.parent);

      return menus;
    }

    async function setMenuList(data: any) {
      let menus = convert2Menus(data);

      menuList.value = menus;

      localStorage.setItem("stepin-menu", JSON.stringify(menus));

      addRoutes(toRoutes(menus));

      //checkMenuPermission();
    }

    async function getMenuList() {
      loading.value = true;
      return http
        .get("/admin/user/profile")
        .then((res: any) => {
          if (res.code == 200) {
            let permissions = ["edit", "delete", "add"]; //response.data.menus.map(f => f.path);

            const { setAuthorities } = useAuthStore();
            setAuthorities(permissions);
            setMenuList(res.data.menus);
            return res;
          }
        })
        .finally(() => (loading.value = false));

      // loading.value = true;
      // return new Promise<MenuProps[]>((resolve, reject) => {
      //     let data = JSON.parse(localStorage.getItem('stepin-menu')) as MenuProps[];
      //     setTimeout(() => {
      //         resolve(data);
      //     }, 500);
      //
      // })
      //     .then(res => {
      //         if (res == null) {
      //             return [];
      //         }
      //         menuList.value = res;
      //         addRoutes(toRoutes(res));
      //         checkMenuPermission();
      //         return res;
      //     }).finally(() => (loading.value = false));
    }

    async function listAll() {
      loading.value = true;
      let res = await http.get("/admin/menu/all");

      let menus = convert2Menus(res.data);
      return menus;
    }

    async function addMenu(menu: MenuProps) {
      let data = {
        name: menu.title,
        icon: menu.icon,
        component: menu.component,
        path: menu.path,
        showMenu: menu.renderMenu ? 1 : 0,
        parentId: menu.parent ? menu.parent.substr(5, 1) : 0,
      };
      return http
        .request<any, Response<any>>("/admin/menu/add", "POST_JSON", data)
        .then((res) => {
          return res.data;
        })
        .finally(getMenuList);
    }

    async function updateMenu(menu: MenuProps) {
      loading.value = true;
      console.log(menu);
      let data = {
        id: menu.id,
        name: menu.title,
        icon: menu.icon,
        component: menu.component,
        path: menu.path,
        showMenu: menu.renderMenu ? 1 : 0,
        parentId: menu.parent ? menu.parent.substr(5, 2) : 0,
      };
      return http
        .post<any, Response<any>>("/admin/menu/update", data)
        .then((res) => {
          return res.data;
        })
        .finally(getMenuList);
    }

    async function removeMenu(id: number) {
      return http
        .post<any, Response<any>>("/admin/menu/delete/" + id, "POST")
        .then(async (res) => {
          if (res.code === 200) {
            removeRoute("menu_" + res.data.id);
          }
        })
        .finally(getMenuList);
    }

    return {
      loading,
      menuList,
      getMenuList,
      listAll,
      setMenuList,
      addMenu,
      updateMenu,
      removeMenu,
    };
  },
  {
    persist: {
      enabled: true,
    },
  }
);
